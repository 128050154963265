import * as React from 'react'
import { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'
import querystring from 'querystring'

import { slicesMap } from '../slices/PageLayout'
import { mapDataToPropsEnhancer } from '../templates/page'

import { Layout } from '../components/Layout'
import { useSiteSettings } from '../hooks/useSiteSettings'

const sliceList = [
  { __typename: 'PageLayoutHeader', id: 'header' },
  { __typename: 'PageLayoutSearchResults', id: 'search-results' },
  { __typename: 'PageLayoutFooter', id: 'footer' },
]

export const SearchPage = ({ location }: PageProps) => {
  const siteSettings = useSiteSettings()
  const params = location
    ? querystring.decode(location.search.replace(/^\?/, ''))
    : {}
  const query = params.query && String(params.query ?? '').trim()

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  const meta = React.useMemo(() => ({ location }), [location])

  return (
    <Layout>
      <Helmet>
        <title>
          {query ? `Search results for ${query}` : 'Search'} |{' '}
          {siteSettings.siteName}
        </title>
      </Helmet>
      <MapSlicesToComponents
        list={sliceList}
        map={slicesMap}
        meta={meta}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      />
    </Layout>
  )
}

export default SearchPage
